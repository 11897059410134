import React from "react";

const AsciiArt = () => {
  return (
    <div>
      <pre>
        {`
                                                  _    
                                                 | |   
   _ __   __ _  __ _  ___    _ __ ___   __ _ _ __| | __
  | '_ \\ / _\` |/ _\` |/ _ \\  | '_ \` _ \\ / _\` | '__| |/ /
  | | | | (_| | (_| | (_) | | | | | | | (_| | |  |   < 
  |_| |_|\\__,_|\\__, |\\___/  |_| |_| |_|\\__,_|_|  |_|\\_\\
                __/ |                                  
               |___/                                   
        `}
      </pre>
    </div>
  );
};

export default AsciiArt;
